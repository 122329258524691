import React, { useState, useEffect } from "react";
import Navigation from "../components/navigation";
import { Helmet } from "react-helmet";
import candidateData from "../../candidates.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faUndoAlt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function SchoolBoardElection2021() {
  const [questions, setQuestions] = useState([]);
  const [removedCandidates, setRemovedCandidates] = useState([]);
  const [removedQuestions, setRemovedQuestions] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const removeQuestion = (newQuestion) => {
    let newQuestions = [...removedQuestions];
    newQuestions.push(newQuestion);
    setRemovedQuestions(newQuestions);
  };

  const removeCandidate = (candidateIdx) => {
    let newCandidates = [...removedCandidates];
    newCandidates.push(candidateIdx);
    setRemovedCandidates(newCandidates);
  };

  const unremoveQuestion = (questionIdx) => {
    let newQuestions = [...removedQuestions];
    newQuestions.splice(questionIdx, 1);
    setRemovedQuestions(newQuestions);
  };

  const unremoveCandidate = (idx) => {
    let newCandidates = [...removedCandidates];
    newCandidates.splice(idx, 1);
    setRemovedCandidates(newCandidates);
  };

  const reset = () => {
    setRemovedCandidates([]);
    setRemovedQuestions([]);
  };

  useEffect(() => {
    let newQuestions = [];
    for (let question in candidateData[0]) {
      newQuestions.push(question);
      console.log(question);
    }
    setQuestions(newQuestions);
  }, []);

  return (
    <div className="font-sans text-base text-black">
      <Navigation />
      <div className="container py-4 px-4 lg:px-12 xl:px-24">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Who Should I Vote For in the SLPS School Board Race?</title>
          <meta
            name="description"
            content="Turns out there's a school board race on April 6th, who knew?"
          />
          <meta property="og:site_name" content="STL Policy Project" />
          <meta
            property="og:title"
            content="Who Should I Vote For in the SLPS School Board Race?"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content="Turns out there's a school board race on April 6th, who knew?"
          />
          <meta property="og:url" content="https://stlpolicyproject.com" />
        </Helmet>
        <div className="mb-8">
          <h1 className="text-5xl mb-8 font-raleway font-light">
            Who Should I Vote For School Board?
          </h1>
          <p className="text-base mb-4">
            I definitely can't tell you who to vote for. I didn't even realize
            there was a school board race in Saint Louis on April 6th until last
            week, but I frantically did some research on the race and the
            candidates. If you too are just realizing there's a school board
            race perhaps this will help!
          </p>
          <h2 className="text-3xl mb-4 font-raleway font-light">
            First Some Quick Facts:
          </h2>
          <p className="text-base mb-4">
            There are ten Candidates vying for three spots, no party
            affliations!
          </p>
          <p className="text-base mb-4">
            There are seven spots on the school board in total, but only three
            are up during this election. Only one incumbent is running, but two
            former members are running again.
          </p>
          <p className="text-base mb-8">
            This race matters more than ever since local control returned to
            SLPS's school board!
          </p>
          <h2 className="text-3xl mb-4 font-raleway font-light">
            How I Did the Research:
          </h2>
          <p className="text-base mb-4">
            I watched the candidate forum put on by the League of Women Voters.
            I read the entries for each candidate on Vote411. I read the entries
            for each candidate on the SLPS Foundation website. I read (not in
            full) each candidates website (if they had one).
          </p>
          <p className="text-base">
            <span className="font-bold">Small Disclaimer:</span> Candidate
            responses to forum questions are paraphrased below. Additionally the
            other categories are based on the information I was able to find. If
            there are any mistakes my apologies!
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-3xl mb-4 font-raleway font-light">
            The Candidates:
          </h2>
          <legend className="text-base mb-4">
            Table scrolls to show more candidates. Click on a candidate name to
            see just their responses, click on a question to see all the
            candidate responses to that question. You can click the X to remove
            a candidate or question you don't want on the table.
          </legend>
          {removedCandidates.length > 0 && (
            <div>
              <span className="font-bold">Removed Candidates: </span>
              {removedCandidates.map((candidateIdx, idx) => {
                return (
                  <span
                    role="button"
                    className="italic"
                    tabIndex={0}
                    onClick={() => {
                      unremoveCandidate(idx);
                    }}
                    onKeyDown={() => {
                      unremoveQuestion(idx);
                    }}
                  >
                    {idx === 0 ? "" : ", "}
                    {candidateData[candidateIdx].Name}
                  </span>
                );
              })}
            </div>
          )}
          {removedQuestions.length > 0 && (
            <div>
              <span className="font-bold">Removed Questions: </span>
              {removedQuestions.map((question, idx) => {
                return (
                  <span
                    role="button"
                    className="italic"
                    tabIndex={0}
                    onClick={() => {
                      unremoveQuestion(idx);
                    }}
                    onKeyDown={() => {
                      unremoveQuestion(idx);
                    }}
                  >
                    {idx === 0 ? "" : ", "}
                    {question}
                  </span>
                );
              })}
            </div>
          )}
          {(removedQuestions.length > 0 || removedCandidates.length) > 0 && (
            <button onClick={reset}>
              <FontAwesomeIcon icon={faUndoAlt} size="sm" /> Reset
            </button>
          )}
          {selectedCandidate === null && !selectedQuestion && (
            <div className="w-full overflow-scroll h-screen">
              <table className="table-fixed text-sm">
                {questions.map((question) => {
                  if (question === "Name") {
                    return (
                      <thead>
                        <tr>
                          <th className="sticky top-0 z-20 font-bold border-2 w-80 border-black p-1 md:p-2 bg-white">
                            {question}
                          </th>
                          {candidateData.map((candidate, idx) => {
                            return (
                              <th
                                className={`sticky top-0 z-20 font-bold border-t-2 border-b-2 border-r-2 border-black bg-white p-1 md:p-2${
                                  removedCandidates.includes(idx)
                                    ? " hidden"
                                    : ""
                                }`}
                              >
                                <button
                                  className="font-bold"
                                  onClick={() => setSelectedCandidate(idx)}
                                >
                                  {candidate[question]}
                                </button>
                                <button
                                  className="absolute top-1 right-1"
                                  onClick={() => {
                                    removeCandidate(idx);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTimes} size="sm" />
                                </button>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                    );
                  }

                  return (
                    <tr
                      className={`border-b-2 border-black bg-gray-100 even:bg-gray-300${
                        removedQuestions.includes(question) ? " hidden" : ""
                      }`}
                    >
                      <td className="relative font-bold border-l-2 border-r-2 border-black p-1 md:p-2">
                        <button
                          className="font-bold"
                          onClick={() => {
                            setSelectedQuestion(question);
                          }}
                        >
                          {question}
                        </button>
                        <button
                          className="absolute top-1 right-1"
                          onClick={() => {
                            removeQuestion(question);
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} size="sm" />
                        </button>
                      </td>
                      {candidateData.map((candidate, idx) => {
                        return (
                          <td
                            className={`border-r-2 border-black p-1 md:p-2${
                              removedCandidates.includes(idx) ? " hidden" : ""
                            }`}
                          >
                            {question === "Website" ? (
                              <a
                                href={candidate[question]}
                                className="break-all text-blue-600 hover:text-black"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {candidate[question]}
                              </a>
                            ) : (
                              candidate[question]
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
          {selectedQuestion && (
            <div className="text-center mt-4">
              <button
                className="mb-4 font-raleway hover:bg-black hover:text-white px-4 py-2 border border-black"
                onClick={() => {
                  setSelectedQuestion(null);
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" /> Return to Table
                View
              </button>
              <h2 className="text-3xl font-raleway font-light mb-4">
                {selectedQuestion}
              </h2>
              {candidateData.map((candidate, idx) => {
                if (!removedCandidates.includes(idx)) {
                  return (
                    <div className="mb-4">
                      <p className="font-bold mb-2">{candidate.Name}</p>
                      <p>{candidate[selectedQuestion]}</p>
                    </div>
                  );
                }
                return "";
              })}
            </div>
          )}
          {selectedCandidate !== null && (
            <div className="text-center mt-4 font-light">
              <button
                className="mb-4 font-raleway hover:bg-black hover:text-white px-4 py-2 border border-black"
                onClick={() => {
                  setSelectedCandidate(null);
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" /> Return to Table
                View
              </button>
              <h2 className="text-3xl mb-4 font-raleway ">
                {candidateData[selectedCandidate].Name}
              </h2>
              {Object.entries(candidateData[selectedCandidate]).map(
                ([question, answer]) => {
                  if (
                    !removedQuestions.includes(question) &&
                    question !== "Name"
                  ) {
                    return (
                      <div className="mb-4">
                        <p className="font-bold mb-2">{question}</p>
                        <p>{answer}</p>
                      </div>
                    );
                  }
                  return "";
                }
              )}
            </div>
          )}
        </div>
        <div>
          <h2 className="text-3xl mb-4 font-raleway font-light">
            Additional Resources
          </h2>
          <ul>
            <li className="mb-2">
              <a
                href="https://www.vote411.org/"
                className="text-blue-600 font-bold hover:text-black"
                target="_blank"
                rel="noreferrer"
              >
                Vote 411
              </a>{" "}
              is always a great resource
            </li>
            <li className="mb-2">
              <a
                href="https://slpsfoundation.org/forum/"
                className="text-blue-600 font-bold hover:text-black"
                target="_blank"
                rel="noreferrer"
              >
                The SLPS Foundation
              </a>{" "}
              has the aforementioned candidate forum as well as info on each
              candidate.
            </li>
            <li className="mb-2">
              <a
                href="https://www.facebook.com/119073881437784/videos/1097925480712594"
                className="text-blue-600 font-bold hover:text-black"
                target="_blank"
                rel="noreferrer"
              >
                Saint Louis Young Democrats
              </a>{" "}
              also did a forum.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
